import React, { useContext } from "react";
import {
  AnimationLayout,
  AppContext,
  Heading,
  Section,
  Wrapper,
} from "../../shared";
import ProductCard from "../../components/Cards/ProductCard";

const AccessoryPage = () => {
  const { appData } = useContext(AppContext);
  const ACCESSORIES_LIST = appData?.ACCESSORIES;

  // Dynamically import all images
  const importAll = (r) => {
    let images = {},
      index = -1;
    r.keys().forEach((key) => {
      index++;
      images[index] = r(key);
    });
    return images;
  };

  // eslint-disable-next-line no-unused-vars
const images = importAll(
  require.context("../../assets/images/accessories", false, /\.webp$/)
);


  return (
    <Wrapper>
      <Section className="w-full text-center items-center justify-center">
        <Heading
          type="heading"
          className="mb-5 container"
          title="Enhance Your Drone Experience with DJI Bangalore Accessories"
          subTitle={`"DJI's precision-crafted accessories enhance every flight, from extended battery life to safe transport solutions. 
                Explore our collection for seamless compatibility and elevated aerial adventures."`}
        />

        <div className="mx-auto mt-10">
          {/* <div className="grid grid-cols-1 border border-blue-800 sm:grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4"> */}
          <div className="mx-0 md:mx-5 grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 2xl:grid-cols-4 gap-4">
            {/* {ACCESSORIES_DETAILS.map((obj, index) => (
              <AnimationLayout
                type="fadeIn"
                direction="up"
                delay={0.1 * (index + 0.1)}
                className="group relative h-full shadow-md px-2 py-4 rounded-lg"
                key={index}
              >
                <div>
                  <img
                    src={images[index]}
                    alt={obj.name}
                    className="w-full h-full object-cover mb-4 rounded-lg"
                  />
                </div>
                <div className="font-semibold">
                  <div className="text-lg mb-2">{obj.name}</div>
                  <div className="bg-shadow w-min mx-auto px-4 py-1 rounded-md text-gray-700 inline-block">
                    {"\u20B9"}&nbsp;{obj.price}
                  </div>
                </div>
              </AnimationLayout>
            ))} */}
            {ACCESSORIES_LIST?.map((data, index) => (
              <AnimationLayout
                type="fadeIn"
                direction="up"
                delay={0.1 * (index + 0.1)}
                className="group relative h-full"
                key={index}
              >
                <ProductCard type="bottom-details" key={index} data={data} />
              </AnimationLayout>
            ))}
          </div>
        </div>
      </Section>
    </Wrapper>
  );
};

export default AccessoryPage;
