import React from "react";
import { BsMotherboard } from "react-icons/bs";
import { GiVideoCamera } from "react-icons/gi";
import { IoWarningOutline } from "react-icons/io5";
import { MdOutlineSensors, MdOutlineWaterDrop } from "react-icons/md";
import ContactUsCs from "../../assets/images/Contact-us-cs.webp";
import bannerimage from "../../assets/images/service/service-banner.webp";
import { Section, Wrapper } from "../../shared";
import BannerCard from "../../components/Cards/BannerCard";
const ServicePage = () => {
  return (
    <Wrapper>
      <Section>
        <div className="w-full h-1/2 flex items-center justify-between px-0 md:px-20 py-5 perspective shadow-2xl bg-gradient-to-t  from-transparent via-transparent to-shadow">
          <div className="text-left font-semibold text-3xl">
            Service Request & Inquiry
          </div>
          <img
            src={bannerimage}
            className="w-1/4 h-1/4 object-cover hidden md:flex"
            alt=""
          />
        </div>
        <div className="container items-center justify-center mt-16">
          <div className="text-center mb-6">
            <span className="text-xl py-1 px-3 rounded-lg bg-shadow font-semibold inline-block">
              Specializing in professional DJI drone service and repairs for all
              models.
            </span>
          </div>
          <div className="grid grid-cols-1 sm:grid-cols-5 gap-4 mt-16">
            {/* Card 1 */}
            <div className="bg-shadow shadow-md p-4 rounded-lg flex flex-col justify-center items-center hover:cardHover">
              <MdOutlineSensors className="w-12 h-12 mb-2" />
              <span className="text-center">Vision Sensor</span>
            </div>

            {/* Card 2 */}
            <div className="bg-shadow shadow-md p-4 rounded-lg flex flex-col justify-center items-center hover:cardHover">
              <IoWarningOutline className="w-12 h-12 mb-2" />
              <span className="text-center">Error Remove For All Models</span>
            </div>

            {/* Card 3 */}
            <div className="bg-shadow shadow-md p-4 rounded-lg flex flex-col justify-center items-center hover:cardHover">
              <BsMotherboard className="w-12 h-12 mb-2" />
              <span className="text-center">Motherboard Repair</span>
            </div>

            {/* Card 4 */}
            <div className="bg-shadow shadow-md p-4 rounded-lg flex flex-col justify-center items-center hover:cardHover">
              <MdOutlineWaterDrop className="w-12 h-12 mb-2" />
              <span className="text-center">Water Damage</span>
            </div>

            {/* Card 5 */}
            <div className="bg-shadow shadow-md p-4 rounded-lg flex flex-col justify-center items-center hover:cardHover">
              <GiVideoCamera className="w-12 h-12 mb-2" />
              <span className="text-center">Gimbal Repair</span>
            </div>
          </div>
        </div>
        <Section className="container">
          <img
            className="w-full object-center h-full shadow-lg"
            src={ContactUsCs}
            alt="Contact Us Service"
          />
        </Section>
      </Section>
      <BannerCard data={{}} type="quickchat" />
    </Wrapper>
  );
};

export default ServicePage;
